import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const EnablePlugin = () => {
  return (
    <LayoutComponent>
      <h2>Enable GAINSS Plugin in a JIRA project</h2>
      <div className="testimonials">
        <h5>Note</h5>
        <p>
          You must be a site admin or project admin to{" "}
          <strong>enable/disable</strong> GAINSS for your project.
        </p>
      </div>
      <ol>
        <li>
          Open your Jira project. Select Project settings from the left side
          menu.
        </li>
        <li>
          Click Gainss-Test Intelligence Solution when the Apps drop-down
          appears.
        </li>
        <li>Click on plugin configuration</li>
        <li>Enable/ disable the plugin</li>
      </ol>
    </LayoutComponent>
  )
}

export default EnablePlugin
